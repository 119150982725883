@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn{
  font-size: 21px;
  padding: 5px 20px;
  border: 0;
  background-color: #3498db;
  color: #fff;
  border-radius: 3px;
  transition: all 250ms ease-in-out;
  cursor: pointer;
}

.btn:hover{
  background-color: #2386c8;
}

.btn:focus{
  outline: none;
}

html, body{
  width:100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}



.homepage {
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.row {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    align-content: stretch;
    width: 100%;
    max-width: 1200px;
  }


.grid-row{
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-wrap: wrap;
    padding: 4px;
    width: 100%;
    height: 200px;
}

.grid-row a { 
    margin-top: 4px;
    vertical-align: middle;
    height: 100%;
    
}
.grid-row a img{ 
    max-width: 200px;
    max-height: 100%;
}

@media(max-width:480px){
    .grid-row a img{ 
    max-width: 180px;
    max-height: 100%;
    }
}


.grid{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.page {
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.coltwo {
    width: 50%;
    float: left;
    text-align:center;
    align-items: center;
    vertical-align: center;
    height:100%;
    padding-left: 30px;
    padding-right:30px;
}

.mission-container {
    width: 100%;
    max-height: 150px;
    display: flex;
    justify-content: center;
    background: #E0E1E4;
    overflow: hidden;
    padding: 10px 0 10px 0;
    position: relative;
}

.about-us {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin-top: 30px;
}

.colthree {
    width: 33%;
    float: left;
}

.our-brands {
    max-width: 1200px;
    margin: 0 auto;
}
.our-brands img {
    margin-bottom: 60px;
}

.our-brands h2{
    font-size: 2.2em;
    line-height: 42px;
    margin: 0 0 44px 0;
    color: #0e0e0e;
    font-weight: 300;
    letter-spacing:-1px;
    font-family:inherit;
    letter-spacing: -1px;
    margin-bottom: 32px;
}

.our-brands .link{
    font-size: 2em;
    color: #0B2D37;

}

.our-brands hr{
    margin: 44px 0 44px 0;
    background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
    border: 0;
    height: 1px;
    box-sizing: content-box;
    color: #777777;
}

.missionText {
    color: #777777;
    display: inline-block;
    font-size: 1.4em;
    font-weight:300;
    padding-top: 5px;
    line-height:24px;
    font-family:inherit;
}
.missionTextHighlight {
    color: #1CAE59;
    font-family: inherit, cursive;
    font-size:1.6em;
    font-weight:300;
}

.mission-container button{
    border-radius: 4px;
    background-color: #1CAE59;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    padding: 20px;
    width: 160px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
    height: 80%;
}

.mission-container button span{
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;    
}

.mission-container button span:after{
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;  
}

.mission-container button:hover span {
    padding-right: 25px;
  }

  .mission-container button:hover span:after {
    padding-right: 25px;
  }

.banner-container {
    background-image: linear-gradient(#c8e3f7, white);
    width: 100%;
    height: 500px;
}
.banner{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: center;
    height: 100%;
}

.slide-one {
    background-image:url(/static/media/light-cover1.f123443e.jpg);
    background-position: center top;
    height: 500px;
    width:100%; 
    overflow:hidden;
    background-size: 2250px 500px;
    background-repeat: no-repeat;
}

.slide-two{
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}

.slide-two .slidetwoimg{

    height: 480px;
    transition: transform 1s;
}

.slick-active .slide-two .slidetwoimg{
    transform: translate3d(0px, 0px, 0px) scale(0.9);
    transform-origin: 50% 50% 0px;
}
.slide-two .slidetwotitle4{
    min-height: 0px;
    min-width: 0px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-size: 28px;
    visibility: visible;
    opacity: 1;
    color: #0B2D37;
    font-weight: 300;
    position: relative;
    left:50px;
    transition: transform 1s;
}


.slick-active .slide-two .slidetwotitle4{
    transform: translate3d(-50px, 0px, 0px);
}

.slide-two .slidetwotitle3{
    color: #0B2D37;
    min-height: 0px;
    min-width: 0px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-size: 28px;
    visibility: visible;
    opacity: 1;
    color:  #1CAE59;
    font-weight: 500;
    position: relative;
    bottom:50px;
    transition-delay: 1s;
    transition: transform 1s;
}


.slick-active .slide-two .slidetwotitle3{
    transform: translate3d(0px, 50px, 0px);
}

.slide-two .slidetwotitle2{
    min-height: 0px;
    min-width: 0px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-size: 435%;
    visibility: visible;
    font-weight: 900;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    position: relative;
    bottom:50px;
    transition: transform 1s;
}

.slick-active .slide-two .slidetwotitle2{
    transform: translate3d(0px, 50px, 0px);
}

.slide-two .slidetwobutton{
    color:  white;
    background-color: #0B2D37;
    border-color: #0B2D37;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33px;
    border-radius: 6px;
    height: 50px;
    z-index:100;
}
.slick-prev::before,
.slick-next::before {
    color: rgba(0, 0, 0, 0.3) !important;
    font-size: 2.5rem !important;
}

.slick-prev,
.slick-next {
    width: 2.5rem !important;
    height: 2.5rem !important;
    z-index: 2;
    color: rgba(0, 0, 0, 0.3) !important;
}


.slick-prev {
    left: 0 !important;
}

.slick-next {
    right: 0 !important;
}

.banner-container .slick-list{
    height: 100%;
}

.product-page-container .slick-list{
    width: 100%;
}

.product-page-container .slick-slide img{
    max-height: 525px;
    width: auto !important;
}

.slick-track{
    height: 100%;
}

/* NAVBAR STUFF */

.bsnavbar {
    background-color:#f6f6f6;
}

.navbar-toggler{
    background-color: #0B2D37 !important;
}


.contact-us-nav {
    font-family: "Open Sans", Arial, sans-serif;
    font-size:12px;
    font-style: normal;
    text-transform: uppercase;
    font-weight: 700;
    visibility: visible !important;
}
.navbar-brand {
    width: 75%;
}
.spacer {
    height:100px;
    background-color:#f6f6f6;
}

.mr-auto label{
    display: block;
    height: 100px;
    width: 120px;
    background-size: 120px 100px;
    margin-bottom:0;
}

.mr-auto .checked{
    background-color: #d6d6d6;
}

.mr-auto label:hover{
    background-color: #d6d6d6;
}

.mr-auto button{
    border-radius: 0px;
    border: none;
    height: 100px;
    max-width: 120px;
    width: 10vw;
    color: #0B2D37;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    font-style: normal;
    font-family: inherit;
    background-color:#f6f6f6;
}

.mr-auto button:checked{
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    color: #0B2D37;
}

.mr-auto button:hover{
    background-color: #d6d6d6;
    color: #0B2D37;
}

.contact-nav button{
    border-radius: 4px;
    background-color: #0B2D37;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 16px;
    padding: 20px;
    min-width: 175px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
    height: 100%;
}

.contact-nav button span{
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;    
}

.contact-nav button span:after{
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;  
}

.contact-nav button:hover span {
    padding-right: 25px;
}



.navbar-dropdown-active button{
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    color: #0B2D37;
}

/* Homepage Banner Small */
@media (max-width: 480px){
    .banner-container {
        background-image: linear-gradient(#c8e3f7, white);
        width: 100%;
        height: 47vw;
    }

    .slide-one {
        background-image:url(/static/media/light-cover1.f123443e.jpg);
        background-position: center top;
        height: 500px;
        width:100%; 
        overflow:hidden;
        background-size: 200vw;
        background-repeat: no-repeat;
    }
    
    .slide-two .slidetwotitle4{
        display: none;
        min-height: 0px;
        min-width: 0px;
        border-width: 0px;
        margin: 0px;
        padding: 0px;
        letter-spacing: 0px;
        font-size: 2.5vw;
        line-height: 2.5vw;
        visibility: visible;
        opacity: 1;
        color: #0B2D37;
        font-weight: 300;
        position: relative;
        left:50px;
        transition: transform 1s;
    }
    
    
    .slick-active .slide-two .slidetwotitle4{
        transform: translate3d(-50px, 0px, 0px);
    }
    
    .slide-two .slidetwotitle3{
        display: none;
        content: "";
        color: #0B2D37;
        min-height: 0px;
        min-width: 0px;
        border-width: 0px;
        margin: 0px;
        padding: 0px;
        letter-spacing: 0px;
        font-size: 2.5vw;
        line-height: 2.5vw;
        visibility: visible;
        opacity: 1;
        color:  #1CAE59;
        font-weight: 500;
        position: relative;
        bottom:50px;
        transition-delay: 1s;
        transition: transform 1s;
    }
    
    
    .slick-active .slide-two .slidetwotitle3{
        transform: translate3d(0px, 50px, 0px);
    }
    
    .slide-two .slidetwotitle2{
        min-height: 0px;
        min-width: 0px;
        border-width: 0px;
        margin: 0px;
        padding: 0px;
        letter-spacing: 0px;
        font-size: 6vw;
        visibility: visible;
        font-weight: 900;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
        position: relative;
        bottom:50px;
        transition: transform 1s;
        line-height: 6vw;
    }
    
    .slick-active .slide-two .slidetwotitle2{
        transform: translate3d(0px, 50px, 0px);
    }
    
    .slidetwobreak{
        font-size: 0;
    }
    
    .slide-two .slidetwobutton{
        color:  white;
        background-color: #0B2D37;
        border-color: #0B2D37;
        padding: 10px 16px;
        font-size: 2.5vw;
        line-height: 1.33px;
        border-radius: 6px;
        height: 4vw;
        z-index:100;
    }
    
    .slide-two .slidetwoimg{
        height: 39vw;
        transition: transform 1s;
    }
    
    .slick-active .slide-two .slidetwoimg{
        transform: translate3d(0px, 0px, 0px) scale(0.9);
        transform-origin: 50% 50% 0px;
    }
    
}
/* Homepage Mission Medium */
@media (max-width: 579px){
    .missionText {
        color: #777777;
        display: inline-block;
        font-size: 4vw;
        font-weight:300;
        padding-top: 5px;
        line-height:4vw;
        font-family:inherit;
    }
    .missionTextHighlight {
        color: #1CAE59;
        font-family: inherit, cursive;
        font-size:5vw;
        font-weight:300;
    }
    
}
/* Homepage Brands Medium */
@media (max-width: 625px){
    .our-brands h2{
        font-size: 2.2em;
        line-height: 42px;
        margin: 0 0 44px 0;
        color: #0e0e0e;
        font-weight: 300;
        letter-spacing:-1px;
        font-family:inherit;
        letter-spacing: -1px;
        margin-bottom: 32px;
    }
    .our-brands hr{
        margin: 44px 0 44px 0;
        background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
        border: 0;
        height: 1px;
        box-sizing: content-box;
        color: #777777;
    }

    .our-brands img {
        margin-bottom: 60px;
    }

    .our-brands .colthree {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: stretch;
        width: 100%;
        max-width: 1200px;
    }
}
/* Homepage Banner Medium */
@media (max-width: 1200px){
    .banner-container {
        background-image: linear-gradient(#c8e3f7, white);
        width: 100%;
        height: 41vw;
    }
    
    .slide-one {
        background-image:url(/static/media/light-cover1.f123443e.jpg);
        background-position: center top;
        height: 500px;
        width:100%; 
        overflow:hidden;
        background-size: 200vw;
        background-repeat: no-repeat;
    }

    .slide-two .slidetwotitle4{
        min-height: 0px;
        min-width: 0px;
        border-width: 0px;
        margin: 0px;
        padding: 0px;
        letter-spacing: 0px;
        font-size: 2.5vw;
        line-height: 2.5vw;
        visibility: visible;
        opacity: 1;
        color: #0B2D37;
        font-weight: 300;
        position: relative;
        left:50px;
        transition: transform 1s;
    }
    
    
    .slick-active .slide-two .slidetwotitle4{
        transform: translate3d(-50px, 0px, 0px);
    }
    
    .slide-two .slidetwotitle3{
        color: #0B2D37;
        min-height: 0px;
        min-width: 0px;
        border-width: 0px;
        margin: 0px;
        padding: 0px;
        letter-spacing: 0px;
        font-size: 2.5vw;
        line-height: 2.5vw;
        visibility: visible;
        opacity: 1;
        color:  #1CAE59;
        font-weight: 500;
        position: relative;
        bottom:50px;
        transition-delay: 1s;
        transition: transform 1s;
    }
    
    
    .slick-active .slide-two .slidetwotitle3{
        transform: translate3d(0px, 50px, 0px);
    }
    
    .slide-two .slidetwotitle2{
        min-height: 0px;
        min-width: 0px;
        border-width: 0px;
        margin: 0px;
        padding: 0px;
        letter-spacing: 0px;
        font-size: 6vw;
        visibility: visible;
        font-weight: 900;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
        position: relative;
        bottom:50px;
        transition: transform 1s;
        line-height: 6vw;
    }
    
    .slick-active .slide-two .slidetwotitle2{
        transform: translate3d(0px, 50px, 0px);
    }
    
    .slidetwobreak{
        font-size: 0;
    }
    
    .slide-two .slidetwobutton{
        color:  white;
        background-color: #0B2D37;
        border-color: #0B2D37;
        padding: 10px 16px;
        font-size: 1.5vw;
        line-height: 1.33px;
        border-radius: 6px;
        height: 4vw;
        z-index:100;
    }
    
    .slide-two .slidetwoimg{
        height: 39vw;
        transition: transform 1s;
    }
    
    .slick-active .slide-two .slidetwoimg{
        transform: translate3d(0px, 0px, 0px) scale(0.9);
        transform-origin: 50% 50% 0px;
    }
}

/* Navbar Small */
.bsnavbar-small{
    display: none !important;
}
@media (max-width: 575px){
    .bsnavbar{
        display: none !important;
    }
    .bsnavbar-medium {
        display:none !important;
    }  
    .bsnavbar-small {
        display:flex !important;
        height: 100px;
        background-color:#f6f6f6;
    }

    .bsnavbar-small .mr-auto a{
        width:30vw;
        height: 50px;
    }
    .bsnavbar-small .mr-auto button{
        width:100%;
        height: 50px;
    }
    .bsnavbar-small .mr-auto{
        margin-right: 0 !important;
        margin-left: 65vw;
    }
}
/* Navbar Medium */
.bsnavbar-medium{
    display: none !important;
}
@media (min-width: 576px) and (max-width: 860px){
    .bsnavbar{
        display: none !important;
    }
    .bsnavbar-small{
        display: none !important;
    }
    .bsnavbar-medium .mr-auto{
        margin-right: 0 !important;
    }
    
    .bsnavbar-medium .navbar-dropdown button{ 
        width: 30vw;
    }
    
    .bsnavbar-medium .navbar-dropdown-active button{ 
        width: 30vw;
    }
    .bsnavbar-medium {
        display:flex !important;
        height: 100px;
        background-color:#f6f6f6;
    }    
}
.footer{
    color: white;
    background: #0B2932;
    font-size: 0.9em;
    padding: 70px 0 0;
    position: relative;
    clear: both;
    border-top-width: 4px;
    border-top-style: solid;
    border-top-color: #092229;
    bottom: 0;
    width: 100%;
    display: block;
    color: #777777;
    font-family: "Open Sans", Arial, sans-serif;
    line-height: 22px;
}

.footer-container {
    width: 1170px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    font-size: 0.9em;

}

.footer-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    max-width: 1200px;
    margin: auto;
    align-items: flex-start;
}

.footer-row  h4 {
    font-size: 1.8em;
    font-weight: 200;
    color: #FFF !important;
    letter-spacing: normal;
    line-height: 27px;
    margin: 0 0 14px 0;
    font-family: inherit;
}
.footer-row ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-row a {
    color: white !important;
}
.column {

    margin-right: 40px;
    float: inline-start;
}


.footer-ribbon {
    margin-bottom: 25px;
    background-color: #1CAE59;
    position: absolute;
    margin: -90px 0 0 14px;
    padding: 10px 20px 6px 20px;
    color: #fff;
    font-family: inherit, cursive;
    font-size: 1.6em;
}

.footer-ribbon .ribbon {
    color: #FFF !important;
}

.footer-copyright {
    background: #05181F;
    border-top: 4px solid #041217;
    margin-top: 40px;
    padding: 30px 0 10px;
}
.contact-us-container {
    margin-top: 30px;
    max-width: 1170px;
    width: 100vw;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    color: #777777;
    font-family: inherit;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
}

.contact-us-container hr{
    margin: 44px 0 44px 0;
    background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
    border: 0;
    height: 1px;
    box-sizing: content-box;
    color: #777777;
}

.contact-info{
    display: flex;
flex-wrap: wrap;
max-width: 1200px;
align-items: initial;
margin: auto;
}

.contact-info .col {
    padding-left: 0;
}

.contact-us-container h2{
    font-size: 2.2em;
    font-weight: 300;
    line-height: 42px;
    margin: 0 0 32px 0;
    color: #0e0e0e;
    letter-spacing: -1px;
}

.contact-info {
    color: #777777;
    line-height: 24px;
    margin: 0 0 20px;
    font-size: 18px;
}

.contact-info h3{
    color: #1CAE59;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 27px;
    margin: 0 0 14px 0;
}

.contact-info b{
    font-weight: 700;
}

.request-form {
    padding-left: 15px;
    margin-bottom: 30px;
    margin-top: 45px;
    background: linear-gradient(to bottom, white 1%, #f9f9f9 98%) repeat scroll 0 0 transparent;
    box-sizing: border-box;
    border-bottom: 1px solid #DFDFDF;
    border-left: 1px solid #ECECEC;
    border-radius: 8px;
    border-right: 1px solid #ECECEC;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
    margin-left: auto;
    margin-right: auto;
    min-height: 100px;
    position: relative;
    z-index: 1;
    border-top: 3px solid #0B2D37;
    padding: 30px 30px 10px 30px;
}

.request-form .col{
    padding-left: 0;

}
.request-form .row{
    margin-bottom: 15px;
}

.input {
    display: block;
    width: 100%;
    height: auto;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin: 0;
    font-family: inherit;
    overflow: visible;
    text-align: left;
}

form label {
    display: inline-block;
    margin-bottom: .5rem;
    color: #777777;
    font-family: inherit;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    font-weight: 400;
}

form label input{
    border-color: #0B2D37 !important;
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #0B2D37;
    float: right !important;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    -webkit-user-select: none;
            user-select: none;
    border: 1px solid transparent;
    border-radius: 4px;
    font-family: inherit;
    margin-top: .5rem;
}

form label input:hover{
    background-color: #1CAE59;
}
form label b {
    color: red;
}
.pp-header{
    background-color: #E0F2F8;
    border-bottom: 2px solid #CCC;
    margin-bottom: 35px;
    min-height: 50px;
    padding: 0;
    padding: 20px 0;
    position: relative;
    text-align: left;
    display: block;
    color: #777777;
    font-family: inherit;
    font-size: 16px;
    line-height: 22px;
}
.pp-header-container{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    text-align: left;
    width: 1170px;
}

.pp-header-container h1{
    border-bottom: 5px solid #1CAE59;
    color: #0B2D37;
    display: inline-block;
    font-weight: 200;
    margin: 0 0 -25px;
    min-height: 37px;
    font-size: 2.5em;
    line-height: 46px;
    padding: 0 0 17px;
    position: relative;
    letter-spacing: -1px;
}
.brand {
    line-height: 24px;
    margin: 0 0 20px;
    text-align: left;
}

.brand img {
    margin-left: -35px;
    vertical-align: middle;
}

.product-page-container {
    max-width: 1170px;
    width: 100vw;
    margin-right: auto;
    margin-left: auto;
    color: #777777;
    font-family: inherit;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
}
.product-page {
    text-align:  left;
}

.brand-description {
    font-size: 1.6em;
    line-height: 1.5em;
    margin-bottom: 20px;
    font-weight: 300;
}
.info {
    max-width: 1170px;
    width: 100vw;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    color: #777777;
    font-family: inherit;
    font-size: 21px;
    line-height: 1.4;
    text-align: left;
    font-weight: 300;
}

.info h2 {
    font-size: 2.2em;
    font-weight: 300;
    line-height: 42px;
    margin: 0 0 32px 0;
    color: #0e0e0e;
    letter-spacing: -1px;
}

.info-text b {
    font-weight: 600;
}
.info-text {
    margin-bottom: 30px;
}

.info h3{
    font-weight: 300;
    margin-bottom: 15px;
}

.product-row {
    display: flex;
    justify-content: center;
    align-items: initial;
    flex-direction: column;
}

.product-row-container {
    display: flex;
    justify-content: center;
    align-items: initial;
}

.product-row hr {
    margin: 44px 0 44px 0;
    background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
    border: 0;
    height: 1px;
    box-sizing: content-box;
    color: #777777;
}

.product-image {
    float:left;
    width: 25%;
}

.product-image img {
    object-fit: cover;
    max-height: 250px;
    width: 100%;
}

.product-info {
    float: left;
    padding: 18px;
    width: 75%;
    color: #777777;
    font-family: inherit;
    font-size: 16px;
    line-height: 22px;
}

.product-info h2 {
    line-height: 130%;
    color: #1CAE59;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: normal;
}

.product-info button{
    font-size: 21px;
    padding: 5px 20px;
    border: 0;
    background-color: #1CAE59;
    color: #fff;
    border-radius: 3px;
    transition: all .25s ease-in-out;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    margin: 0;
    font-family: inherit;
}

.product-info button:hover{
    background-color: rgb(20, 145, 72);
}

.product-description {
    margin: 0 0 20px;
}

.clientimg{
    float: left;
    margin-top: 5px;
    margin-right: 5px;
    height: 100px;
    max-width: 90vw;
}
.charity .modal-image{
    width:100%;
    padding-right:40px;
}

.product_page .row {
    margin-top: -30px;
    display: flex;
    align-items: flex-start;
}


.brand-pic {
    margin-left: -65px;
    vertical-align: middle;
}

.product-page h2{
    line-height: 130%;
    color: #1CAE59;
    font-size: 1.8em;
    font-weight: 400;
    letter-spacing: normal;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: inherit;
}

.product-page h3{
    color: #1CAE59;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 27px;
    margin: 0 0 14px 0;
    font-family: inherit;
}

.product-page .bir {
    color: #81683D;
    font-weight: 700;
    font-size: 1em;
    letter-spacing: normal;
    line-height: 18px;
    text-transform: uppercase;
    font-family: inherit;
}

.product-page h4 {
    color: #1CAE59;
    font-weight: 700;
    line-height: 30px;
    font-size: 16px;
    font-family: inherit;
}

.product .lead{
    font-weight: 300;
    line-height: 1.4;
    font-size: 21px;
}

.product-page p{
    color: #777777;
    line-height: 24px;
    margin: 0 0 20px;
    font-family: inherit;
    font-size: 1.25rem;
    font-weight: 300;
}

.product-page li {
    color: #777777;
    line-height: 24px;
    font-family: inherit;
    font-size: inherit;
}

.product-page tr {
    color: #777777;
    line-height: 24px;
    margin: 0 0 20px;
    font-family: inherit;
    font-size: 18px;
}

.product-page  p ul {
    font-size: inherit;
}

.product-page p .smallimg2{
    float: left;
    margin: 5px 5px 5px 5px;
    height: 15%;
    width: 15%;
}

.product-page p .smallimg{
    float: left;
    margin: 5px 5px 5px 5px;
    margin-right: 20px;
}

.product-page .coltwo{
    width: 50%;
    float: left;
    text-align:left;
    align-items: center;
    vertical-align: center;
    height:100%;
    padding-left: 30px;
    padding-right:30px;
}

.pdfbutton { 
    margin-right: 10px;
}

.product-page {
    min-height: 750px;
}

.product-page p strong {
    color: #81683D;
    font-weight: 700;
}

.product-page li strong {
    color: #81683D;
    font-weight: 700;
}

.large-description {
    margin-top: 40px;
}

.tab-container {
    border-radius: 0 0 4px 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
    background-color: #FFF;
    border: 1px solid #EEE;
    border-top: 0;
    padding: 15px;
    color: #777777;
    padding-left: 30px;
    padding-right: 30px;
}

.tab-container li {
    margin-left: 20px;
}

.tab-container .row {
    margin-top: 15px;
}

.tab-container .pic-col {
    width: 33%;
    margin-left: 20px;
}
.tab-container .pic-col img{
    width: 100%;
}

.spec-table {
    border: 1px solid #ddd;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;
    visibility: visible;
}

.tab-container p ul{
    color: #777777;
    line-height: 24px;
    margin: 0 0 20px;
    font-family: inherit;
    font-size: 18px;
}

.product-page p .red {
    color: red;
}

.product-page hr{
    margin: 22px 0 22px 0;
    background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
    border: 0;
    height: 1px;
    box-sizing: content-box;
    color: #777777;
}

.product-page .become-dealer {
    font-size: 1.6em;
    line-height: 1.5em;
    font-weight: 300;
    margin-bottom: 20px;
    margin-left: 15px;
}

.product-page-container .slick-current{
    max-width: 525px !important;
}

.product-page-container .slick-slider{
    max-height: 550px;
    max-width: 525px;
}

@media (max-width: 1080px){
    .product-page-container .coltwo{
        width:100%;
        margin-bottom:30px;
    }
}

.col-two {
    width: 50%;
    float: left;
}

.embeddedimg {
    width: 100%;
}

@media (max-width: 550px){
    .tab-container .pic-col{
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        align-content: stretch;
        width: 100%;
        max-width: 1200px;
    }
    .tab-container .col{
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        align-content: stretch;
        width: 100%;
        max-width: 1200px;
    }
}
.modal-background-true{
    opacity:0;
    pointer-events:none;
    transition: opacity 0.4s ease-in-out;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    display: flex;
    border:none;
}

.modal-background-false{
    display:none;
}

.modal-showing-true{
    opacity: 1;
    pointer-events: visible;
}

.modal-inner{
    height:100%;
    margin: auto;
    vertical-align: middle;
}

.modal-inner img{
    max-height: 90vh;
    width: 100%;
    margin-top:110px;
}

.modal-image{
    margin-top: 5px;
    margin-bottom: 5px;
}
