.modal-background-true{
    opacity:0;
    pointer-events:none;
    transition: opacity 0.4s ease-in-out;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    display: flex;
    border:none;
}

.modal-background-false{
    display:none;
}

.modal-showing-true{
    opacity: 1;
    pointer-events: visible;
}

.modal-inner{
    height:100%;
    margin: auto;
    vertical-align: middle;
}

.modal-inner img{
    max-height: 90vh;
    width: 100%;
    margin-top:110px;
}

.modal-image{
    margin-top: 5px;
    margin-bottom: 5px;
}