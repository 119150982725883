.clientimg{
    float: left;
    margin-top: 5px;
    margin-right: 5px;
    height: 100px;
    max-width: 90vw;
}
.charity .modal-image{
    width:100%;
    padding-right:40px;
}
