.brand {
    line-height: 24px;
    margin: 0 0 20px;
    text-align: left;
}

.brand img {
    margin-left: -35px;
    vertical-align: middle;
}

.product-page-container {
    max-width: 1170px;
    width: 100vw;
    margin-right: auto;
    margin-left: auto;
    color: #777777;
    font-family: inherit;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
}
.product-page {
    text-align:  left;
}

.brand-description {
    font-size: 1.6em;
    line-height: 1.5em;
    margin-bottom: 20px;
    font-weight: 300;
}