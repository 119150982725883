.homepage {
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.row {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    align-content: stretch;
    width: 100%;
    max-width: 1200px;
  }


.grid-row{
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-wrap: wrap;
    padding: 4px;
    width: 100%;
    height: 200px;
}

.grid-row a { 
    margin-top: 4px;
    vertical-align: middle;
    height: 100%;
    
}
.grid-row a img{ 
    max-width: 200px;
    max-height: 100%;
}

@media(max-width:480px){
    .grid-row a img{ 
    max-width: 180px;
    max-height: 100%;
    }
}


.grid{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.page {
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.coltwo {
    width: 50%;
    float: left;
    text-align:center;
    align-items: center;
    vertical-align: center;
    height:100%;
    padding-left: 30px;
    padding-right:30px;
}

.mission-container {
    width: 100%;
    max-height: 150px;
    display: flex;
    justify-content: center;
    background: #E0E1E4;
    overflow: hidden;
    padding: 10px 0 10px 0;
    position: relative;
}

.about-us {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin-top: 30px;
}

.colthree {
    width: 33%;
    float: left;
}

.our-brands {
    max-width: 1200px;
    margin: 0 auto;
}
.our-brands img {
    margin-bottom: 60px;
}

.our-brands h2{
    font-size: 2.2em;
    line-height: 42px;
    margin: 0 0 44px 0;
    color: #0e0e0e;
    font-weight: 300;
    letter-spacing:-1px;
    font-family:inherit;
    letter-spacing: -1px;
    margin-bottom: 32px;
}

.our-brands .link{
    font-size: 2em;
    color: #0B2D37;

}

.our-brands hr{
    margin: 44px 0 44px 0;
    background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
    border: 0;
    height: 1px;
    box-sizing: content-box;
    color: #777777;
}

.missionText {
    color: #777777;
    display: inline-block;
    font-size: 1.4em;
    font-weight:300;
    padding-top: 5px;
    line-height:24px;
    font-family:inherit;
}
.missionTextHighlight {
    color: #1CAE59;
    font-family: inherit, cursive;
    font-size:1.6em;
    font-weight:300;
}

.mission-container button{
    border-radius: 4px;
    background-color: #1CAE59;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    padding: 20px;
    width: 160px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
    height: 80%;
}

.mission-container button span{
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;    
}

.mission-container button span:after{
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;  
}

.mission-container button:hover span {
    padding-right: 25px;
  }

  .mission-container button:hover span:after {
    padding-right: 25px;
  }

.banner-container {
    background-image: linear-gradient(#c8e3f7, white);
    width: 100%;
    height: 500px;
}
.banner{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: center;
    height: 100%;
}

.slide-one {
    background-image:url(../img/light-cover1.jpg);
    background-position: center top;
    height: 500px;
    width:100%; 
    overflow:hidden;
    background-size: 2250px 500px;
    background-repeat: no-repeat;
}

.slide-two{
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}

.slide-two .slidetwoimg{

    height: 480px;
    transition: transform 1s;
}

.slick-active .slide-two .slidetwoimg{
    transform: translate3d(0px, 0px, 0px) scale(0.9);
    transform-origin: 50% 50% 0px;
}
.slide-two .slidetwotitle4{
    min-height: 0px;
    min-width: 0px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-size: 28px;
    visibility: visible;
    opacity: 1;
    color: #0B2D37;
    font-weight: 300;
    position: relative;
    left:50px;
    transition: transform 1s;
}


.slick-active .slide-two .slidetwotitle4{
    transform: translate3d(-50px, 0px, 0px);
}

.slide-two .slidetwotitle3{
    color: #0B2D37;
    min-height: 0px;
    min-width: 0px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-size: 28px;
    visibility: visible;
    opacity: 1;
    color:  #1CAE59;
    font-weight: 500;
    position: relative;
    bottom:50px;
    transition-delay: 1s;
    transition: transform 1s;
}


.slick-active .slide-two .slidetwotitle3{
    transform: translate3d(0px, 50px, 0px);
}

.slide-two .slidetwotitle2{
    min-height: 0px;
    min-width: 0px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-size: 435%;
    visibility: visible;
    font-weight: 900;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    position: relative;
    bottom:50px;
    transition: transform 1s;
}

.slick-active .slide-two .slidetwotitle2{
    transform: translate3d(0px, 50px, 0px);
}

.slide-two .slidetwobutton{
    color:  white;
    background-color: #0B2D37;
    border-color: #0B2D37;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33px;
    border-radius: 6px;
    height: 50px;
    z-index:100;
}
.slick-prev::before,
.slick-next::before {
    color: rgba(0, 0, 0, 0.3) !important;
    font-size: 2.5rem !important;
}

.slick-prev,
.slick-next {
    width: 2.5rem !important;
    height: 2.5rem !important;
    z-index: 2;
    color: rgba(0, 0, 0, 0.3) !important;
}


.slick-prev {
    left: 0 !important;
}

.slick-next {
    right: 0 !important;
}

.banner-container .slick-list{
    height: 100%;
}

.product-page-container .slick-list{
    width: 100%;
}

.product-page-container .slick-slide img{
    max-height: 525px;
    width: auto !important;
}

.slick-track{
    height: 100%;
}

/* NAVBAR STUFF */

.bsnavbar {
    background-color:#f6f6f6;
}

.navbar-toggler{
    background-color: #0B2D37 !important;
}


.contact-us-nav {
    font-family: "Open Sans", Arial, sans-serif;
    font-size:12px;
    font-style: normal;
    text-transform: uppercase;
    font-weight: 700;
    visibility: visible !important;
}
.navbar-brand {
    width: 75%;
}
.spacer {
    height:100px;
    background-color:#f6f6f6;
}

.mr-auto label{
    display: block;
    height: 100px;
    width: 120px;
    background-size: 120px 100px;
    margin-bottom:0;
}

.mr-auto .checked{
    background-color: #d6d6d6;
}

.mr-auto label:hover{
    background-color: #d6d6d6;
}

.mr-auto button{
    border-radius: 0px;
    border: none;
    height: 100px;
    max-width: 120px;
    width: 10vw;
    color: #0B2D37;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    font-style: normal;
    font-family: inherit;
    background-color:#f6f6f6;
}

.mr-auto button:checked{
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    color: #0B2D37;
}

.mr-auto button:hover{
    background-color: #d6d6d6;
    color: #0B2D37;
}

.contact-nav button{
    border-radius: 4px;
    background-color: #0B2D37;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 16px;
    padding: 20px;
    min-width: 175px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
    height: 100%;
}

.contact-nav button span{
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;    
}

.contact-nav button span:after{
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;  
}

.contact-nav button:hover span {
    padding-right: 25px;
}



.navbar-dropdown-active button{
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    color: #0B2D37;
}

/* Homepage Banner Small */
@media (max-width: 480px){
    .banner-container {
        background-image: linear-gradient(#c8e3f7, white);
        width: 100%;
        height: 47vw;
    }

    .slide-one {
        background-image:url(../img/light-cover1.jpg);
        background-position: center top;
        height: 500px;
        width:100%; 
        overflow:hidden;
        background-size: 200vw;
        background-repeat: no-repeat;
    }
    
    .slide-two .slidetwotitle4{
        display: none;
        min-height: 0px;
        min-width: 0px;
        border-width: 0px;
        margin: 0px;
        padding: 0px;
        letter-spacing: 0px;
        font-size: 2.5vw;
        line-height: 2.5vw;
        visibility: visible;
        opacity: 1;
        color: #0B2D37;
        font-weight: 300;
        position: relative;
        left:50px;
        transition: transform 1s;
    }
    
    
    .slick-active .slide-two .slidetwotitle4{
        transform: translate3d(-50px, 0px, 0px);
    }
    
    .slide-two .slidetwotitle3{
        display: none;
        content: "";
        color: #0B2D37;
        min-height: 0px;
        min-width: 0px;
        border-width: 0px;
        margin: 0px;
        padding: 0px;
        letter-spacing: 0px;
        font-size: 2.5vw;
        line-height: 2.5vw;
        visibility: visible;
        opacity: 1;
        color:  #1CAE59;
        font-weight: 500;
        position: relative;
        bottom:50px;
        transition-delay: 1s;
        transition: transform 1s;
    }
    
    
    .slick-active .slide-two .slidetwotitle3{
        transform: translate3d(0px, 50px, 0px);
    }
    
    .slide-two .slidetwotitle2{
        min-height: 0px;
        min-width: 0px;
        border-width: 0px;
        margin: 0px;
        padding: 0px;
        letter-spacing: 0px;
        font-size: 6vw;
        visibility: visible;
        font-weight: 900;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
        position: relative;
        bottom:50px;
        transition: transform 1s;
        line-height: 6vw;
    }
    
    .slick-active .slide-two .slidetwotitle2{
        transform: translate3d(0px, 50px, 0px);
    }
    
    .slidetwobreak{
        font-size: 0;
    }
    
    .slide-two .slidetwobutton{
        color:  white;
        background-color: #0B2D37;
        border-color: #0B2D37;
        padding: 10px 16px;
        font-size: 2.5vw;
        line-height: 1.33px;
        border-radius: 6px;
        height: 4vw;
        z-index:100;
    }
    
    .slide-two .slidetwoimg{
        height: 39vw;
        transition: transform 1s;
    }
    
    .slick-active .slide-two .slidetwoimg{
        transform: translate3d(0px, 0px, 0px) scale(0.9);
        transform-origin: 50% 50% 0px;
    }
    
}
/* Homepage Mission Medium */
@media (max-width: 579px){
    .missionText {
        color: #777777;
        display: inline-block;
        font-size: 4vw;
        font-weight:300;
        padding-top: 5px;
        line-height:4vw;
        font-family:inherit;
    }
    .missionTextHighlight {
        color: #1CAE59;
        font-family: inherit, cursive;
        font-size:5vw;
        font-weight:300;
    }
    
}
/* Homepage Brands Medium */
@media (max-width: 625px){
    .our-brands h2{
        font-size: 2.2em;
        line-height: 42px;
        margin: 0 0 44px 0;
        color: #0e0e0e;
        font-weight: 300;
        letter-spacing:-1px;
        font-family:inherit;
        letter-spacing: -1px;
        margin-bottom: 32px;
    }
    .our-brands hr{
        margin: 44px 0 44px 0;
        background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
        border: 0;
        height: 1px;
        box-sizing: content-box;
        color: #777777;
    }

    .our-brands img {
        margin-bottom: 60px;
    }

    .our-brands .colthree {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: stretch;
        width: 100%;
        max-width: 1200px;
    }
}
/* Homepage Banner Medium */
@media (max-width: 1200px){
    .banner-container {
        background-image: linear-gradient(#c8e3f7, white);
        width: 100%;
        height: 41vw;
    }
    
    .slide-one {
        background-image:url(../img/light-cover1.jpg);
        background-position: center top;
        height: 500px;
        width:100%; 
        overflow:hidden;
        background-size: 200vw;
        background-repeat: no-repeat;
    }

    .slide-two .slidetwotitle4{
        min-height: 0px;
        min-width: 0px;
        border-width: 0px;
        margin: 0px;
        padding: 0px;
        letter-spacing: 0px;
        font-size: 2.5vw;
        line-height: 2.5vw;
        visibility: visible;
        opacity: 1;
        color: #0B2D37;
        font-weight: 300;
        position: relative;
        left:50px;
        transition: transform 1s;
    }
    
    
    .slick-active .slide-two .slidetwotitle4{
        transform: translate3d(-50px, 0px, 0px);
    }
    
    .slide-two .slidetwotitle3{
        color: #0B2D37;
        min-height: 0px;
        min-width: 0px;
        border-width: 0px;
        margin: 0px;
        padding: 0px;
        letter-spacing: 0px;
        font-size: 2.5vw;
        line-height: 2.5vw;
        visibility: visible;
        opacity: 1;
        color:  #1CAE59;
        font-weight: 500;
        position: relative;
        bottom:50px;
        transition-delay: 1s;
        transition: transform 1s;
    }
    
    
    .slick-active .slide-two .slidetwotitle3{
        transform: translate3d(0px, 50px, 0px);
    }
    
    .slide-two .slidetwotitle2{
        min-height: 0px;
        min-width: 0px;
        border-width: 0px;
        margin: 0px;
        padding: 0px;
        letter-spacing: 0px;
        font-size: 6vw;
        visibility: visible;
        font-weight: 900;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
        position: relative;
        bottom:50px;
        transition: transform 1s;
        line-height: 6vw;
    }
    
    .slick-active .slide-two .slidetwotitle2{
        transform: translate3d(0px, 50px, 0px);
    }
    
    .slidetwobreak{
        font-size: 0;
    }
    
    .slide-two .slidetwobutton{
        color:  white;
        background-color: #0B2D37;
        border-color: #0B2D37;
        padding: 10px 16px;
        font-size: 1.5vw;
        line-height: 1.33px;
        border-radius: 6px;
        height: 4vw;
        z-index:100;
    }
    
    .slide-two .slidetwoimg{
        height: 39vw;
        transition: transform 1s;
    }
    
    .slick-active .slide-two .slidetwoimg{
        transform: translate3d(0px, 0px, 0px) scale(0.9);
        transform-origin: 50% 50% 0px;
    }
}

/* Navbar Small */
.bsnavbar-small{
    display: none !important;
}
@media (max-width: 575px){
    .bsnavbar{
        display: none !important;
    }
    .bsnavbar-medium {
        display:none !important;
    }  
    .bsnavbar-small {
        display:flex !important;
        height: 100px;
        background-color:#f6f6f6;
    }

    .bsnavbar-small .mr-auto a{
        width:30vw;
        height: 50px;
    }
    .bsnavbar-small .mr-auto button{
        width:100%;
        height: 50px;
    }
    .bsnavbar-small .mr-auto{
        margin-right: 0 !important;
        margin-left: 65vw;
    }
}
/* Navbar Medium */
.bsnavbar-medium{
    display: none !important;
}
@media (min-width: 576px) and (max-width: 860px){
    .bsnavbar{
        display: none !important;
    }
    .bsnavbar-small{
        display: none !important;
    }
    .bsnavbar-medium .mr-auto{
        margin-right: 0 !important;
    }
    
    .bsnavbar-medium .navbar-dropdown button{ 
        width: 30vw;
    }
    
    .bsnavbar-medium .navbar-dropdown-active button{ 
        width: 30vw;
    }
    .bsnavbar-medium {
        display:flex !important;
        height: 100px;
        background-color:#f6f6f6;
    }    
}