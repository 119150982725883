.product-row {
    display: flex;
    justify-content: center;
    align-items: initial;
    flex-direction: column;
}

.product-row-container {
    display: flex;
    justify-content: center;
    align-items: initial;
}

.product-row hr {
    margin: 44px 0 44px 0;
    background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
    border: 0;
    height: 1px;
    box-sizing: content-box;
    color: #777777;
}

.product-image {
    float:left;
    width: 25%;
}

.product-image img {
    object-fit: cover;
    max-height: 250px;
    width: 100%;
}

.product-info {
    float: left;
    padding: 18px;
    width: 75%;
    color: #777777;
    font-family: inherit;
    font-size: 16px;
    line-height: 22px;
}

.product-info h2 {
    line-height: 130%;
    color: #1CAE59;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: normal;
}

.product-info button{
    font-size: 21px;
    padding: 5px 20px;
    border: 0;
    background-color: #1CAE59;
    color: #fff;
    border-radius: 3px;
    transition: all .25s ease-in-out;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    margin: 0;
    font-family: inherit;
}

.product-info button:hover{
    background-color: rgb(20, 145, 72);
}

.product-description {
    margin: 0 0 20px;
}
