.product_page .row {
    margin-top: -30px;
    display: flex;
    align-items: flex-start;
}


.brand-pic {
    margin-left: -65px;
    vertical-align: middle;
}

.product-page h2{
    line-height: 130%;
    color: #1CAE59;
    font-size: 1.8em;
    font-weight: 400;
    letter-spacing: normal;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: inherit;
}

.product-page h3{
    color: #1CAE59;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 27px;
    margin: 0 0 14px 0;
    font-family: inherit;
}

.product-page .bir {
    color: #81683D;
    font-weight: 700;
    font-size: 1em;
    letter-spacing: normal;
    line-height: 18px;
    text-transform: uppercase;
    font-family: inherit;
}

.product-page h4 {
    color: #1CAE59;
    font-weight: 700;
    line-height: 30px;
    font-size: 16px;
    font-family: inherit;
}

.product .lead{
    font-weight: 300;
    line-height: 1.4;
    font-size: 21px;
}

.product-page p{
    color: #777777;
    line-height: 24px;
    margin: 0 0 20px;
    font-family: inherit;
    font-size: 1.25rem;
    font-weight: 300;
}

.product-page li {
    color: #777777;
    line-height: 24px;
    font-family: inherit;
    font-size: inherit;
}

.product-page tr {
    color: #777777;
    line-height: 24px;
    margin: 0 0 20px;
    font-family: inherit;
    font-size: 18px;
}

.product-page  p ul {
    font-size: inherit;
}

.product-page p .smallimg2{
    float: left;
    margin: 5px 5px 5px 5px;
    height: 15%;
    width: 15%;
}

.product-page p .smallimg{
    float: left;
    margin: 5px 5px 5px 5px;
    margin-right: 20px;
}

.product-page .coltwo{
    width: 50%;
    float: left;
    text-align:left;
    align-items: center;
    vertical-align: center;
    height:100%;
    padding-left: 30px;
    padding-right:30px;
}

.pdfbutton { 
    margin-right: 10px;
}

.product-page {
    min-height: 750px;
}

.product-page p strong {
    color: #81683D;
    font-weight: 700;
}

.product-page li strong {
    color: #81683D;
    font-weight: 700;
}

.large-description {
    margin-top: 40px;
}

.tab-container {
    border-radius: 0 0 4px 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
    background-color: #FFF;
    border: 1px solid #EEE;
    border-top: 0;
    padding: 15px;
    color: #777777;
    padding-left: 30px;
    padding-right: 30px;
}

.tab-container li {
    margin-left: 20px;
}

.tab-container .row {
    margin-top: 15px;
}

.tab-container .pic-col {
    width: 33%;
    margin-left: 20px;
}
.tab-container .pic-col img{
    width: 100%;
}

.spec-table {
    border: 1px solid #ddd;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;
    visibility: visible;
}

.tab-container p ul{
    color: #777777;
    line-height: 24px;
    margin: 0 0 20px;
    font-family: inherit;
    font-size: 18px;
}

.product-page p .red {
    color: red;
}

.product-page hr{
    margin: 22px 0 22px 0;
    background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
    border: 0;
    height: 1px;
    box-sizing: content-box;
    color: #777777;
}

.product-page .become-dealer {
    font-size: 1.6em;
    line-height: 1.5em;
    font-weight: 300;
    margin-bottom: 20px;
    margin-left: 15px;
}

.product-page-container .slick-current{
    max-width: 525px !important;
}

.product-page-container .slick-slider{
    max-height: 550px;
    max-width: 525px;
}

@media (max-width: 1080px){
    .product-page-container .coltwo{
        width:100%;
        margin-bottom:30px;
    }
}

.col-two {
    width: 50%;
    float: left;
}

.embeddedimg {
    width: 100%;
}

@media (max-width: 550px){
    .tab-container .pic-col{
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        align-content: stretch;
        width: 100%;
        max-width: 1200px;
    }
    .tab-container .col{
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        align-content: stretch;
        width: 100%;
        max-width: 1200px;
    }
}