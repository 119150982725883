.footer{
    color: white;
    background: #0B2932;
    font-size: 0.9em;
    padding: 70px 0 0;
    position: relative;
    clear: both;
    border-top-width: 4px;
    border-top-style: solid;
    border-top-color: #092229;
    bottom: 0;
    width: 100%;
    display: block;
    color: #777777;
    font-family: "Open Sans", Arial, sans-serif;
    line-height: 22px;
}

.footer-container {
    width: 1170px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    font-size: 0.9em;

}

.footer-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    max-width: 1200px;
    margin: auto;
    align-items: flex-start;
}

.footer-row  h4 {
    font-size: 1.8em;
    font-weight: 200;
    color: #FFF !important;
    letter-spacing: normal;
    line-height: 27px;
    margin: 0 0 14px 0;
    font-family: inherit;
}
.footer-row ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-row a {
    color: white !important;
}
.column {

    margin-right: 40px;
    float: inline-start;
}


.footer-ribbon {
    margin-bottom: 25px;
    background-color: #1CAE59;
    position: absolute;
    margin: -90px 0 0 14px;
    padding: 10px 20px 6px 20px;
    color: #fff;
    font-family: inherit, cursive;
    font-size: 1.6em;
}

.footer-ribbon .ribbon {
    color: #FFF !important;
}

.footer-copyright {
    background: #05181F;
    border-top: 4px solid #041217;
    margin-top: 40px;
    padding: 30px 0 10px;
}