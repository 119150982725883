.contact-us-container {
    margin-top: 30px;
    max-width: 1170px;
    width: 100vw;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    color: #777777;
    font-family: inherit;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
}

.contact-us-container hr{
    margin: 44px 0 44px 0;
    background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
    border: 0;
    height: 1px;
    box-sizing: content-box;
    color: #777777;
}

.contact-info{
    display: flex;
flex-wrap: wrap;
max-width: 1200px;
align-items: initial;
margin: auto;
}

.contact-info .col {
    padding-left: 0;
}

.contact-us-container h2{
    font-size: 2.2em;
    font-weight: 300;
    line-height: 42px;
    margin: 0 0 32px 0;
    color: #0e0e0e;
    letter-spacing: -1px;
}

.contact-info {
    color: #777777;
    line-height: 24px;
    margin: 0 0 20px;
    font-size: 18px;
}

.contact-info h3{
    color: #1CAE59;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 27px;
    margin: 0 0 14px 0;
}

.contact-info b{
    font-weight: 700;
}

.request-form {
    padding-left: 15px;
    margin-bottom: 30px;
    margin-top: 45px;
    background: linear-gradient(to bottom, white 1%, #f9f9f9 98%) repeat scroll 0 0 transparent;
    box-sizing: border-box;
    border-bottom: 1px solid #DFDFDF;
    border-left: 1px solid #ECECEC;
    border-radius: 8px;
    border-right: 1px solid #ECECEC;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
    margin-left: auto;
    margin-right: auto;
    min-height: 100px;
    position: relative;
    z-index: 1;
    border-top: 3px solid #0B2D37;
    padding: 30px 30px 10px 30px;
}

.request-form .col{
    padding-left: 0;

}
.request-form .row{
    margin-bottom: 15px;
}

.input {
    display: block;
    width: 100%;
    height: auto;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin: 0;
    font-family: inherit;
    overflow: visible;
    text-align: left;
}

form label {
    display: inline-block;
    margin-bottom: .5rem;
    color: #777777;
    font-family: inherit;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    font-weight: 400;
}

form label input{
    border-color: #0B2D37 !important;
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #0B2D37;
    float: right !important;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 4px;
    font-family: inherit;
    margin-top: .5rem;
}

form label input:hover{
    background-color: #1CAE59;
}
form label b {
    color: red;
}