.info {
    max-width: 1170px;
    width: 100vw;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    color: #777777;
    font-family: inherit;
    font-size: 21px;
    line-height: 1.4;
    text-align: left;
    font-weight: 300;
}

.info h2 {
    font-size: 2.2em;
    font-weight: 300;
    line-height: 42px;
    margin: 0 0 32px 0;
    color: #0e0e0e;
    letter-spacing: -1px;
}

.info-text b {
    font-weight: 600;
}
.info-text {
    margin-bottom: 30px;
}

.info h3{
    font-weight: 300;
    margin-bottom: 15px;
}
